import DeviceInfo from 'react-native-device-info';
import { Dimensions, PixelRatio } from 'react-native';
import { UAParser } from 'ua-parser-js';

const DEVICE_ORIENTATION = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE'
};

const CONNECTION_TYPE = {
    CELLULAR: 'cellular',
    WIFI: 'wifi',
    UNKNOWN: 'unknown'
};

const Device = {
    windowSize: null,
    pixelDensity: null,
    width: null,
    height: null,
    adjustedWidth: null,
    adjustedHeight: null,
    isAndroid: false,
    isIos: false,
    isPhone: false,
    isTablet: false,
    isTabletOrWeb: true,
    isIphoneX: false,
    isWeb: true,
    isKeepAwakeActivated: false,
    orientation: null,
    platformString: 'web'
};

const parser = new UAParser();

const isPhoneOrTablet = () => false;

const isIosOrAndroid = () => false;

const detectIphoneX = () => false;

const getActualWidth = () => Dimensions.get('window').width;

const getActualHeight = () => Dimensions.get('window').height;

const getDeviceSize = () => ({
    height: getActualHeight(),
    width: getActualWidth()
});

const getDeviceAspectRatio = () => {
    const { height, width } = getDeviceSize();
    const aspectRatio = width / height;

    const deltaSixteenToNine = Math.abs(aspectRatio - 16 / 9);
    const deltaFourToThree = Math.abs(aspectRatio - 4 / 3);

    if (deltaFourToThree < deltaSixteenToNine) return '4:3';
    return '16:9';
};

const getPlayerAspectRatio = () => {
    const { height, width } = getDeviceSize();
    return width / height;
};

const activateKeepAwake = () => {};

const deactivateKeepAwake = () => {};

const lockToPortrait = () => {};

const lockToLandscape = () => {};

const addOrientationListener = () => {};

const removeOrientationListener = () => {};

const addLockListener = () => {};

const removeLockListener = () => {};

const getOrientation = () => 'LANDSCAPE';

const unlockAllOrientations = () => {};

const isAutoRotateEnabled = () => {};

const getScaledValueByDevice = (value) => {
    let scaledValue = value;

    if (Device.isTablet) {
        scaledValue = value * 2;
    }

    return scaledValue;
};

const setInitialOrientation = () => {
    Device.orientation = DEVICE_ORIENTATION.LANDSCAPE;
};

const initializeState = () => {
    Device.windowSize = Dimensions.get('window');
    Device.pixelDensity = PixelRatio.get();

    Device.width = Device.windowSize.width;
    Device.height = Device.windowSize.height;
    Device.adjustedWidth = Device.width * Device.pixelDensity;
    Device.adjustedHeight = Device.height * Device.pixelDensity;

    setInitialOrientation();
    isPhoneOrTablet();
    isIosOrAndroid();
    detectIphoneX();

    if (Device.isPhone) {
        lockToPortrait();
    }
};

const getNetInfo = () =>
    Promise.resolve({
        isInternetReachable: window.navigator.onLine
    });

const addNetInfoListener = () => () => {};

const getDeviceId = () => {
    const match = document.cookie.match(/deviceId=([^;]+)/);
    if (match && match.length > 0) {
        return match[1];
    }
    const deviceId = `${window.navigator.appName}.${Date.now()}.${Math.random()}`;
    document.cookie = `deviceId=${deviceId};`;

    return deviceId;
};

const getDeviceType = () => 'web';

const getDeviceModel = () => 'Web Browser';

const getDeviceName = () => 'Web Browser';

const getDeviceSystemVersion = () => '0.0.0';

const getAppName = () => 'Web App';

const getAppVersion = () => {
    const pjson = require('../../package.json');
    return pjson.version;
};

const getAppBuildNumber = () => '0';

const getMcoreDeviceType = () => {
    let deviceType = 'OTT_WIN';
    if (navigator.appVersion.indexOf('Mac') !== -1) {
        deviceType = 'OTT_MAC';
    } else if (navigator.appVersion.indexOf('Linux') !== -1) {
        deviceType = 'OTT_LINUX';
    }

    return deviceType;
};

const hideBars = () => {};

const showBars = () => {};

const getDeviceBrightness = () => 1;

const useScreenOrientation = () => {};

const restoreSystemBrightness = () => {};

const saveBrightness = () => {};

const getProduct = () => '';

const getBrand = () => parser.getBrowser().name;

const getUserAgent = () => DeviceInfo.getUserAgent();

const getManufacturer = () => '';

const getModel = () => DeviceInfo.getModel();

const getVersion = () => DeviceInfo.getVersion();

const getSystemVersion = () => '';

const getSystemName = () => '';

const getBuildNumber = () => '';

const getBuildId = () => parser.getBrowser().version;

const getFirstInstallTime = () => '';

const getSerialNumber = () => '';

const getReadableVersion = () => '';

const useNetInfo = () =>
    Promise.resolve({
        isInternetReachable: window.navigator.onLine
    });

/** INITIALIZATION */
addOrientationListener(() => {});

const Orientation = {
    lockToPortrait,
    lockToLandscape,
    addOrientationListener,
    removeOrientationListener,
    addLockListener,
    removeLockListener
};

const setAppBrightness = () => {};

export {
    DEVICE_ORIENTATION,
    CONNECTION_TYPE,
    Device,
    Orientation,
    getActualWidth,
    getActualHeight,
    getDeviceSize,
    activateKeepAwake,
    deactivateKeepAwake,
    lockToPortrait,
    lockToLandscape,
    addNetInfoListener,
    addOrientationListener,
    useScreenOrientation,
    removeOrientationListener,
    addLockListener,
    removeLockListener,
    getScaledValueByDevice,
    getNetInfo,
    useNetInfo,
    getDeviceId,
    getDeviceModel,
    getDeviceType,
    getDeviceName,
    getDeviceSystemVersion,
    getAppName,
    getAppVersion,
    getAppBuildNumber,
    getMcoreDeviceType,
    hideBars,
    initializeState,
    showBars,
    getOrientation,
    unlockAllOrientations,
    isAutoRotateEnabled,
    getDeviceBrightness,
    restoreSystemBrightness,
    saveBrightness,
    getDeviceAspectRatio,
    getPlayerAspectRatio,
    setAppBrightness,
    getUserAgent,
    getProduct,
    getManufacturer,
    getModel,
    getVersion,
    getSystemVersion,
    getSystemName,
    getBuildNumber,
    getBuildId,
    getFirstInstallTime,
    getSerialNumber,
    getBrand,
    getReadableVersion
};
